import Vue from 'vue';
import Vuex from 'vuex';
import db from '../firebase/init.js';
import { ref, onValue, runTransaction } from 'firebase/database';
import { getAuth, signInAnonymously } from 'firebase/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // Firebase data that is used throughout the video player
    votes: {},
    views: null,
    uid: null,
  },
  actions: {
    /**
     * Sets up listeners 🔊 for votes and views.
     */
    firebaseListeners({ state }) {
      let votesRef = ref(db, 'votes');
      let viewsRef = ref(db, 'views');
      onValue(votesRef, (snapshot) => {
        const data = snapshot.val();
        state.votes = data;
      });
      onValue(viewsRef, (snapshot) => {
        const data = snapshot.val();
        state.views = data;
      });
    },
    /**
     * Signs into Firebase and receives a uid.
     * For the sake of the test, this is using ANONYMOUS signin.
     * 🔮 In the Future - I'd like to add a user and authentication system, instead of anonymous.
     */
    firebaseSignin({ state }) {
      const auth = getAuth();
      signInAnonymously(auth)
        .then((res) => {
          // Signed in..
          state.uid = res.user.uid;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * After the detectDecentViewTime check, the number of views is increased.
     * Is dispatched from Controls components.
     */
    firebaseIncrementViews() {
      let viewsRef = ref(db, 'views');
      runTransaction(viewsRef, (views) => {
        if (views) {
          views = views + 1;
        }
        return views;
      });
    },
  },
});
