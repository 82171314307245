/**
 * Organizes initial Firebase realtime configuration and exports it.
 */

import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBuXZdIcfWMyQzTKrMVAppTNbigwp4mcGE",
  authDomain: "eko-player-84d30.firebaseapp.com",
  databaseURL:
    "https://eko-player-84d30-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "eko-player-84d30",
  storageBucket: "eko-player-84d30.appspot.com",
  messagingSenderId: "1008653998258",
  appId: "1:1008653998258:web:fd0263dd8d7ec33ab991ab",
};

const app = initializeApp(firebaseConfig);

// Get a reference to the database service
export default getDatabase(app);
