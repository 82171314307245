<template>
  <div class="vote">
    <!-- shows skeletons until data uid is received from login -->
    <div class="vote-wrap skeletons" v-if="!uid">
      <div class="skeleton thumb"></div>
      <div class="skeleton thumb"></div>
    </div>
    <div class="vote-wrap" v-else>
      <button
        :class="`thumbs-up ${thumbsUp.voted ? 'active' : ''} thumb neumorphism`"
        id="thumbsUp"
        @click="firebaseVoteSet(true)"
        title="I like this"
        :aria-label="thumbsUp.amount + ' likes'"
      >
        <div class="thumb-icon"></div>
        <div class="badge">
          {{ thumbsUp.amount }}
        </div>
      </button>
      <button
        :class="`thumbs-down  ${
          thumbsDown.voted ? 'active' : ''
        } thumb neumorphism`"
        id="thumbsDown"
        @click="firebaseVoteSet(false)"
        title="I dislike this"
        :aria-label="thumbsDown.amount + ' dislikes'"
      >
        <div class="thumb-icon"></div>
        <div class="badge">
          <!-- 
           Making numbers prettier by adding 'k' for numbers over one thousand 
           and 'm' for numbers over one million by using numeraljs's '0a'.
           For example: 1,000 = 1k; 1,000,000 = 1m.
          -->
          {{ thumbsDown.amount | numeral("0a") }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import db from "../../../firebase/init.js";
import { mapState } from "vuex";
import { runTransaction, ref } from "firebase/database";

// Vote component displays, processes and sets votes.
export default {
  name: "Vote",
  data() {
    return {};
  },
  computed: {
    ...mapState(["votes", "uid"]),
    /**
     * Adds up votes length.
     * And current users vote choice by uid.
     */
    thumbsUp() {
      const filtered = this.votesFilter(true);
      const voted = filtered.some((el) => el[0] === this.uid);
      return { amount: filtered.length, voted: voted };
    },
    thumbsDown() {
      const filtered = this.votesFilter(false);
      const voted = filtered.some((el) => el[0] === this.uid);
      return { amount: filtered.length, voted: voted };
    },
  },
  methods: {
    /**
     * For future reference:
     * Voting should be done in a server function
     * Voting should only be done for Authenticated and not Anonymous users.
     * @param {boolean} vote receives true or false
     */
    firebaseVoteSet(vote) {
      let votesRef = ref(db, "votes");
      runTransaction(votesRef, (votes) => {
        votes[this.uid] = vote;
        return votes;
      });
    },
    /**
     * convert votes object to a key/value array.
     * For example: [['id', 'true/false'], ['id', 'true/false'], ...]
     * @param {boolean} vote receives true or false
     */
    votesFilter(vote) {
      const asArray = Object.entries(this.votes);
      const filtered = asArray.filter((value) => value[1] === vote);
      return filtered;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.vote-wrap {
  padding: 10px;
  display: flex;
  .thumb {
    cursor: pointer;
    background: none;
    border: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    @media only screen and (max-width: 768px) {
      padding: 4px 8px;
    }
    border-radius: 30px;
    box-shadow: $neumorphShadow;

    .thumb-icon {
      width: 38px;
      height: 38px;
      @media only screen and (max-width: 768px) {
        width: 32px;
        height: 32px;
      }
      margin-right: 5px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 75%;
      background-image: url(/img/social/like.svg);
    }
    &.active {
      cursor: default;
      box-shadow: $neumorphShadowInset;
      .thumb-icon {
        background-image: url(/img/social/like-solid.svg);
      }
    }
    &.thumbs-down {
      .thumb-icon {
        transform: rotate(180deg);
      }
    }
    &:first-of-type {
      margin-right: 20px;
    }
    .badge {
      font-size: 1rem;
      font-weight: 600;
    }
  }
}
.skeletons {
  display: flex;
  .skeleton {
    width: 72px;
    height: 48px;
  }
}
</style>
