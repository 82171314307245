<template>
  <div class="social">
    <Views />
    <Vote />
  </div>
</template>

<script>
import Views from "@/components/ui/social/Views";
import Vote from "@/components/ui/social/Vote";

// The Social component wraps Views and Vote components.
export default {
  name: "Social",
  components: { Views, Vote },
};
</script>

<style scoped lang="scss">
.social {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
