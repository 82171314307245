
<template>
  <div class="container">
    <div class="player">
      <VideoElement />
      <Controls />
    </div>
    <Social />
  </div>
</template>

<script>
import VideoElement from "@/components/VideoElement";
import Controls from "@/components/ui/Controls";
import Social from "@/components/ui/Social";

/**
 * The Main component wraps the rest of the components.
 * - The player wraps the video player tag and the Controls component,
 *   allowing the controls to be positioned above the video.
 */
export default {
  name: "Main",
  components: {
    VideoElement,
    Controls,
    Social,
  },
  mounted() {
    this.initialFirebaseStarter();
  },
  methods: {
    /**
     * We dispatch the initial actions in order for
     * Firebase to begin listening, sign in, and fill the state.
     * This is activated after the main component has been mounted.
     */
    initialFirebaseStarter() {
      this.$store.dispatch("firebaseSignin");
      this.$store.dispatch("firebaseListeners");
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  padding: 20px;
  margin: 0 auto;
  max-width: 1280px;
  .player {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    .video {
      width: 100%;
      height: 100%;
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.411);
    }
  }
}
</style>

