<template>
  <button
    :class="`play-pause control ${!isVideoPlaying ? 'paused' : ''}`"
    @click="emitTogglePlayPause"
    :title="isVideoPlaying ? 'pause' : 'play'"
  ></button>
</template>

<script>
// Pure CSS Play and Pause Toggle with transition ⏯.
export default {
  name: "PlayPause",
  props: {
    // Is the current video playing or paused.
    isVideoPlaying: { type: Boolean },
  },
  methods: {
    // Emits event that triggers parents togglePlayPause function.
    emitTogglePlayPause() {
      this.$emit("toggle-play-pause");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.play-pause {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 74px;
  transform: scale(0.3) translateX(-100%);

  border-color: transparent transparent transparent $playPauseColor;
  transition: 100ms all ease;
  cursor: pointer;

  // play state
  border-style: double;
  border-width: 0px 0 0px 60px;
  &:hover {
    border-color: transparent transparent transparent $playPauseColor;
  }
  // paused state
  &.paused {
    border-style: solid;
    border-width: 37px 0 37px 60px;
  }
}
</style>

