<template>
  <div
    :class="`controls-overlay ${!isVideoPlaying ? 'paused' : ''}`"
    v-if="canVideoPlay"
  >
    <div role="button" class="top" @click="togglePlayPause">
      <!--
        Toggles play/pause in the same way that the play/pause button does.
        Takes up the remaining space on the video player, excluding the controls. 
      -->
    </div>
    <div class="bottom">
      <PlayPause
        @toggle-play-pause="togglePlayPause"
        :isVideoPlaying="isVideoPlaying"
      />
      <progress
        class="progress"
        :value="currentTime"
        min="0"
        :max="duration"
      ></progress>
      <p class="current-time">{{ currentTimeDisplay }}</p>
    </div>
  </div>
</template>

<script>
import PlayPause from "@/components/ui/controls/PlayPause";

/**
 * In this components we handle videos:
 * - state.
 * - progress.
 * - time and formatted time.
 */
export default {
  name: "Controls",
  components: { PlayPause },
  data() {
    return {
      canVideoPlay: false,
      isVideoPlaying: false,
      video: null,

      // video paramaters from video API
      duration: 0,
      currentTime: 0,
      currentTimeDisplay: "0:00",

      // Time and indicater to weigh decent view time
      timeWatched: 0,
      decentViewTime: false,
    };
  },
  mounted() {
    this.$nextTick(function () {
      this.video = document.getElementById("video");

      this.canPlayListener();

      this.timeUpdateListener();
    });
  },
  methods: {
    /**
     * Toggles play pause button
     * Dictated by if the video is actually playing or not.
     * Is emitted by the PlayPause component (this components child).
     */
    togglePlayPause() {
      let videoPlayer = this.video;
      if (!this.isVideoPlaying) {
        videoPlayer.play();
        this.isVideoPlaying = true;
      } else {
        videoPlayer.pause();
        this.isVideoPlaying = false;
      }
    },
    /**
     * Enabling video controls when the video is actualy ready.
     * Using HTMLMediaElement's canplay event
     */
    canPlayListener() {
      this.video.oncanplay = () => {
        this.canVideoPlay = true;
        this.duration = this.video.duration;
      };
    },
    /**
     * Checking if we are at the end of the video and decent viewing time.
     * The video elements timeupdate event is being used and is fired when the 
     * time indicated by the currentTime attribute has been updated.
     */
    timeUpdateListener() {
      this.video.ontimeupdate = () => {
        this.currentTime = this.video.currentTime;
        this.timeDisplay(this.currentTime);

        // If got to the end of the video toggle back
        if (this.currentTime === this.duration) this.togglePlayPause();

        // Detect decent video view time before actually updating views in the DB.
        if (!this.decentViewTime) this.detectDecentViewTime();
      };
    },
    /**
     * currentTime is seconds only.
     * We want to display it in a more of a hour:minute:seconds format.
     * So we convet it in this function -
     * Tried using Numeraljs formatting but at 60s currenTime, 
     * it displayed 00:60 and not 01:00.
     * Which is less viable for this situation, so timeDisplay is used instead.
     * @param {integer} duration taken in currentTime's duration.
     */
    timeDisplay(duration) {
      var hrs = ~~(duration / 3600);
      var mins = ~~((duration % 3600) / 60);
      var secs = ~~duration % 60;

      if (hrs > 0) {
        this.currentTimeDisplay = `${hrs}:${mins < 10 ? "0" : ""}`;
      }
      this.currentTimeDisplay = `${mins}:${secs < 10 ? "0" : ""}${secs}`;
    },
    /**
     * We want to complicate and legitimize a view.
     * To protect against mass refreshing, clicking or any kind of viewing brutality you can think of
     * This method counts a certain amount of event ticks than decideds that the view is legit.
     */
    detectDecentViewTime() {
      if (this.timeWatched < 30) {
        this.timeWatched++;
      } else {
        this.decentViewTime = true;
        // Dispatches state action to increment views.
        this.$store.dispatch("firebaseIncrementViews");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.controls-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 3px;
  left: 0;
  display: flex;
  flex-direction: column;
  color: $playPauseColor;
  .top {
    width: 100%;
    height: 100%;
  }
  .bottom {
    position: relative;
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 65px;
    padding: 10px 30px;
    @media only screen and (max-width: 768px) {
      height: 50px;
      padding: 10px 20px;
    }
    .progress {
      width: 100%;
      max-width: 1000px;
      margin-right: 10px;
    }
    .current-time {
      font-size: 1.3rem;
    }
  }

  // hover & pause state
  &:hover,
  &.paused {
    background: linear-gradient(
      to bottom,
      transparent 70%,
      rgba(0, 0, 0, 0.472)
    );
    .bottom {
      display: flex;
    }
  }
}
</style>