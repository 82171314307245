<template>
  <!-- shows skeletons until views gets populated in state -->
  <div :class="`views-wrap ${views === null ? 'skeleton' : ''}`">
    <p class="views" v-if="views">{{ views | numeral("0,0") }} views</p>
  </div>
</template>

<script>
import { mapState } from "vuex";

/**
 * Views component displays the amount of time the video has been viewed.
 * The view amount is pulled from the state.
 */
export default {
  name: "Views",
  computed: {
    ...mapState(["views"]),
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";
$neumorph: 7px;
$neumorphNeg: -$neumorph;

.views-wrap {
  font-size: 1.5rem;
  @media only screen and (max-width: 768px) {
    font-size: 1rem;
  }
  font-weight: 600;
  padding: 10px;
  border-radius: 20px;
  box-shadow: $neumorphShadow;
  .views {
    margin: 0;
  }
}
.skeleton {
  height: 48px;
  min-width: 100px;
}
</style>
